<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
      :default-visible="true"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col cols="auto" class="text-right">
            <b-row align-h="end">
              <b-col class="pl-0" cols="auto">
                <export-excel-button
                  :resource="resource"
                  :query="query"
                  :params="params"
                  :loading="loading"
                  :repository="ActivityRepository"
                  :fields="exportFields"
                  :search-fields="searchFields"
                  :file-name="$t('breadcrumb.activity')"
                />
              </b-col>
              <b-col class="px-0" cols="auto">
                <n-column-visibility
                  :fields="fields"
                  :visible-columns="visibleColumns"
                  v-model="visibleColumns"
                  @change="key++"
                ></n-column-visibility>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          :key="`table-${key}`"
          @sort-changed="sortChanged"
          :visible-columns="visibleColumns"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="query.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :resource="resource"
          :route="route"
          :viewable="true"
          :updatable="false"
        >
          <template #cell(purpose)="data">
            {{ $t(`purpose.${data.item.purpose}`) }}
            <div v-if="data.item.purpose == purpose.visitCallPlan">
              <small class="text-primary">
                {{ data.item.outletCode }} -
                {{ trans(data.item, "outletName", $i18n.locale) }}
              </small>
            </div>
            <div v-if="data.item.purpose == purpose.supportSales">
              <small class="text-primary">
                {{ data.item.supportSalespersonIdCard }} -
                {{ data.item.supportSalespersonName }}
              </small>
            </div>
          </template>
          <template #cell(duration)="data">
            <span v-if="data.item.duration">
              {{ toMinutesSeconds(data.item.duration) }}
            </span>
            <span v-else> - </span>
          </template>
          <template #cell(distance)="data">
            <span v-if="data.item.distance">
              {{ Math.round(data.item.distance) }} {{ $t("field.metter") }}
            </span>
            <span v-else> - </span>
          </template>
          <template #back-button="{ item, index }">
            <b-button
              v-b-tooltip.hover
              :title="$t('button.checkout')"
              :disabled="!$can('update', resource) || item.checkoutTime"
              :variant="
                !$can('update', resource) || item.checkoutTime
                  ? 'secondary'
                  : 'danger'
              "
              pill
              size="sm"
              @click="destroy(item.id, index, item)"
            >
              <feather-icon icon="ClockIcon" />
            </b-button>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="query.page"
          @change="changePage"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BBadge, BImg, BLink } from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import ExportExcelFields from "./exportExcelFields";
import SearchInputs from "./searchInput";
import { toMinutesSeconds } from "@/libs/helper";
import purpose from "@/data/purpose";
import NColumnVisibility from "@/components/NColumnVisibility";
import ExportExcelButton from "@/components/ExportExcelButton";

const ActivityRepository = Repository.get("activity");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BImg,
    BLink,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    NColumnVisibility,
    ExportExcelButton,
    vSelect,
  },
  watch: {
    perPage() {
      this.changePage();
      this.$refs.pagination.reset();
    },
  },
  computed: {
    visibleColumns: {
      get() {
        return this.$store.state.column.activity;
      },
      set(value) {
        this.$store.commit("column/SET_ACTIVITY_COLUMN", value);
      },
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      key: 1,
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        createdAt: this.$route.query.createdAt || [],
        regionId: this.$route.query.regionId || [],
        salespersonId: this.$route.query.salespersonId || [],
        purpose: this.$route.query.purpose || [],
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || "DESC",
        createdAt: this.$route.query.createdAt || [],
        regionId: this.$route.query.regionId || [],
        salespersonId: this.$route.query.salespersonId || [],
        purpose: this.$route.query.purpose || [],
      },

      purpose,
      toMinutesSeconds,
      ActivityRepository,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    calculateOrderNumber(index) {
      const from = (this.query.page - 1) * this.perPage + 1;
      return from + index;
    },
    destroy(id, index, data) {
      if (!this.$can("update", this.resource)) {
        return;
      }

      index = this.calculateOrderNumber(index);

      this.$bvModal
        .msgBoxConfirm(
          this.$t("alert.unableToRevertAfterDelete") +
            ` #${index}: ${data.salespersonName}`,
          {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("button.delete"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            ActivityRepository.clearCheckout(id)
              .then((response) => {
                this.getData();
              })
              .catch((error) => {});
          }
        });
    },
    search() {
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.search();
    },
    getData() {
      this.loading = true;
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();

      ActivityRepository.index({
        ...this.query,
        searchFields: this.searchFields,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = TableFields;
    const exportFields = ExportExcelFields;
    const searchFields = SearchInputs;
    const resource = "activity";
    const route = "activity";

    return {
      fields,
      exportFields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>
